import db from "mime-db";

const careerForm = document.getElementById("career-form");
if (careerForm && window["allowedExtensions"]) {
	const allowedExtensions: Array<string> = window["allowedExtensions"];

	const coverLetterFileInput = <HTMLInputElement>document.querySelector("#cover-letter");
	const coverLetterLabel = document.querySelector('label[for="cover-letter"]');
	const defaultLabelText = coverLetterLabel.textContent;
	coverLetterLabel.addEventListener("click", (e) => {
		if (coverLetterLabel.classList.contains("uploaded")) {
			coverLetterLabel.classList.remove("uploaded");
			coverLetterLabel.textContent = defaultLabelText;
			coverLetterFileInput.value = null;
			e.preventDefault();
		}
	});
	coverLetterFileInput.addEventListener("change", () => {
		let textContents = [];
		if (coverLetterFileInput.files.length === 0) {
			coverLetterFileInput.classList.remove("uploaded");
			coverLetterFileInput.textContent = defaultLabelText;
		}
		for (const file of coverLetterFileInput.files) {
			if (!allowedExtensions.includes(db[file.type]?.extensions[0])) {
				alert(allowedExtensions.length > 1 ? window["fileTypeAlertPlural"] : window["fileTypeAlertSingular"]);
				return;
			}
			textContents.push(file.name);
		}
		coverLetterLabel.innerHTML = textContents.map((name) => `<span>${name}</span>`).join("");
		coverLetterLabel.classList.add("uploaded");
	});
}
