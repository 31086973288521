const searchInput = <HTMLInputElement>document.getElementById("header-search-keypress");
const searchButton = document.getElementById("header-search-click");
const searchWrapper = document.querySelector(".search-dropdown-wrap");
const searchDropdown = <HTMLElement>document.querySelector(".search-dropdown");
const searchNotFound = document.querySelectorAll(".search-dropdown .not-found");
const searchFound = <NodeListOf<HTMLElement>>document.querySelectorAll(".search-dropdown .found");
const searchQuickLinks = <NodeListOf<HTMLElement>>document.querySelectorAll(".search-dropdown .quicklink");
let lastTime = new Date();
let searchTimeout: number | null = null;

//serach bar header //
const searchBar = document.querySelector(".search-bar");
const openSearch = document.querySelectorAll(".search-opener");
if (openSearch) {
	openSearch.forEach((open) => {
		open.addEventListener("click", () => {
			if (searchBar) {
				searchBar.classList.add("show");
			}
		});
	});
}
const closeSearch = document.querySelectorAll(".close-search-bar");
if (closeSearch) {
	closeSearch.forEach((close) => {
		close.addEventListener("click", () => {
			if (searchBar) {
				searchBar.classList.remove("show");
			}
		});
	});
}

let ajaxSearch = () => {
	if (searchInput.value.length < 4) {
		return false;
	} else {
		const url = window.location.origin + "/actions/developion-qpoint/search/search";
		const token = document.querySelector<HTMLInputElement>('[name="CRAFT_CSRF_TOKEN"]');
		const body = new FormData();
		body.append(token.getAttribute("name"), token.value);
		body.append("search", searchInput.value);
		fetch(url, {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			redirect: "follow",
			referrerPolicy: "no-referrer",
			body: body,
		})
			.then((response) => response.json())
			.then((response) => {
				searchQuickLinks.forEach((quickLink) => {
					quickLink.style.display = "none";
				});
				searchNotFound.forEach((snf) => {
					snf.remove();
				});
				if (response.length > 0) {
					searchWrapper.classList.add("show");
					searchFound.forEach((sf) => sf.remove());
					response.forEach((item) => {
						const searchItem = `<li class="found"><a href="${item.url}">${item.title}</a></li>`;
						searchDropdown.insertAdjacentHTML("beforeend", searchItem);
					});
				} else {
					let notFound = `<li class="not-found"><span>Nichts gefunden</span></li>`;
					searchDropdown.insertAdjacentHTML("beforeend", notFound);
				}
			})
			.catch((err) => console.log(err));
	}
};

const throttle = () => {
	const now = new Date();
	if (now.getTime() - lastTime.getTime() < 500 && searchTimeout) {
		clearTimeout(searchTimeout);
	}
	searchTimeout = window.setTimeout(() => {
		searchFound.forEach((s) => (s.style.display = "none"));
		ajaxSearch();
	}, 500);
	lastTime = now;
};

const runSearch = (searchString: string) => {
	if (searchString.length >= 4) {
		const site = document.querySelector("[data-site-language]").getAttribute("data-site-language");
		window.location.replace(`${window.location.origin}/${site}/search?query=${searchString}`);
	}
};

if (searchInput) {
	searchInput.addEventListener("keyup", (e) => {
		if (searchWrapper) {
			searchWrapper.classList.add("show");
		}
		if (searchQuickLinks) {
			searchQuickLinks.forEach((quickLink) => {
				quickLink.style.display = "block";
			});
		}
		if ((e.key === "Backspace" || e.key === "Delete") && searchNotFound) {
			searchNotFound.forEach((s) => s.remove());
		}

		if (searchInput.value.length === 0 && searchWrapper) {
			searchWrapper.classList.remove("show");
		}
	});
	searchInput.addEventListener("keydown", (e) => {
		if (e.key === "Enter") {
			runSearch(searchInput.value);
		} else {
			throttle();
		}
	});

	if (searchButton) {
		searchButton.addEventListener("click", () => {
			runSearch(searchInput.value);
		});
	}
}

//search page clear input
const clearInputs = document.querySelectorAll(".clear-input");
const searchPageInput = <HTMLInputElement>document.getElementById("search-page-input");
const searchPageButton = document.getElementById("new-search-click");

if (searchPageInput) {
	searchPageInput.addEventListener("keydown", (e) => {
		if (e.key === "Enter") {
			runSearch(searchPageInput.value);
		} else {
			throttle();
		}
	});

	if (searchPageButton) {
		searchPageButton.addEventListener("click", () => {
			runSearch(searchPageInput.value);
		});
	}
	if (clearInputs) {
		clearInputs.forEach((clearInput) => {
			clearInput.addEventListener("click", () => {
				searchPageInput.value = "";
			});
		});
	}
}
