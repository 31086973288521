import { Autoplay, Navigation, Pagination, EffectFade } from "swiper/modules";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

const homepageSlider = document.querySelector(".home-slider");
if (homepageSlider && document.querySelector(".swiper-pagination")) {
	new Swiper(".home-slider", {
		modules: [Autoplay, EffectFade, Pagination],
		slidesPerView: 1,
		loop: true,
		autoplay: {
			delay: 5000,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		speed: 2000,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});
}

// randomize slides on load //
const iconSlide = document.querySelector(".icons-slider");
if (iconSlide) {
	new Swiper(".icons-slider", {
		modules: [Navigation],
		slidesPerView: 5,
		slidesPerGroup: 5,
		spaceBetween: 50,
		loop: true,
		loopAddBlankSlides: false,
		speed: 900,
		navigation: {
			nextEl: ".icon-next",
			prevEl: ".icon-prev",
		},
		breakpoints: {
			300: {
				slidesPerView: 1,
				slidesPerGroup: 1,
			},
			414: {
				slidesPerView: 2,
				slidesPerGroup: 2,
			},
			600: {
				slidesPerView: 3,
				slidesPerGroup: 3,
			},
			700: {
				slidesPerView: 4,
				slidesPerGroup: 4,
			},
			1024: {
				slidesPerView: 5,
				slidesPerGroup: 5,
			},
		},
	});
}
