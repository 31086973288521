//table//
//open
document.querySelectorAll(".open-table").forEach((item) => {
	item.addEventListener("click", () => {
		item.parentElement.nextElementSibling.classList.add("show");
		item.classList.add("hide");
		item.previousElementSibling.classList.add("show");
	});
});
//close
document.querySelectorAll(".close-table").forEach((item) => {
	item.addEventListener("click", () => {
		item.parentElement.nextElementSibling.classList.remove("show");
		item.classList.remove("show");
		item.nextElementSibling.classList.remove("hide");
	});
});
