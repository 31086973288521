//accordion table//
const accordionBars = <NodeListOf<HTMLElement>>document.querySelectorAll(".accordion-title");
const windowWidth = window.innerWidth;
accordionBars.forEach((accordion) => {
	accordion.addEventListener("click", () => {
		const accordionContent = accordion.nextElementSibling;
		let arrow = accordion.querySelector(".accordion-arrow");
		if (accordionContent.classList.contains("show")) {
			accordionContent.classList.remove("show");
			arrow.classList.remove("rotate");
		} else {
			accordionContent.classList.add("show");
			arrow.classList.add("rotate");
		}
	});
	let icon = accordion.querySelector("div[class*=' alert']");
	if (accordion.contains(icon) && windowWidth < 600) {
		accordion.style.border = "2px solid black";
	}
});
