import Choices from "choices.js";
import "choices.js/src/styles/choices.scss";
import "./SwiperSlider";
import "./Navigation";
import "./Table";
import "./Accordion";
import "./NewsFilter";
import "./Search";
import "./CarrerForm";

//check personal cards number
const personalCards = document.querySelectorAll(".personal-cards .card-wrap");
if (personalCards.length > 3) {
	document.querySelectorAll(".personal-cards").forEach((personalCard) => {
		personalCard.classList.add("align-left");
	});
}

//remove pading from background text-block //
const background = document.querySelector(".has-background");
if (background) {
	background.parentElement.classList.add("no-padding");
}

// color for no job posts
const noJobs = document.querySelector(".no-jobs");
if (noJobs) {
	noJobs.parentElement.classList.add("white");
}

//scroll to top footer//
const top = document.querySelector(".to-the-top");
if (top) {
	top.addEventListener("click", () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	});
}

const landSelect = document.querySelector("#country");
if (landSelect) {
	new Choices(landSelect, {
		searchEnabled: false,
		itemSelectText: "",
		shouldSort: false,
	});
}

document.querySelector("body").classList.remove("preload");
