//mobile menu //
const hamburgerMenu = document.getElementById("hamburger");
if (hamburgerMenu) {
	hamburgerMenu.addEventListener("click", () => {
		const active = hamburgerMenu.classList.toggle("open");
		const mobileMenu = document.querySelector(".mobile-menu");
		if (mobileMenu) {
			mobileMenu.classList.toggle("open", active);
		}
	});
}
