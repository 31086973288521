//append category filters to url //
const filterCategory = (category: string) => {
	let state = {};
	let search = "";
	if (category != "all") {
		search = new URLSearchParams(`category=${category}`).toString();
		state = {
			category: category,
		};
	}
	window.history.pushState(state, "");
	window.location.search = search;
};

//news category active state //
const newsCategories = document.querySelectorAll(".categories .category");
const newsContent = document.getElementById("news-content");
const loadMoreButton = document.getElementById("load-more-btn");

const fetchNews = (category: string, skip: number = 0) => {
	const token = <HTMLInputElement>document.querySelector('[name="CRAFT_CSRF_TOKEN"]');
	const url = window.location.origin + "/actions/developion-qpoint/news/load";
	const language = document.querySelector("[data-site-language]").getAttribute("data-site-language");
	const body = new FormData();
	body.append(token.getAttribute("name"), token.value);
	body.append("numberOfCards", skip.toString());
	body.append("category", category);
	body.append("language", language);

	fetch(url, {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "same-origin",
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: body,
	})
		.then((response) => {
			return response.json();
		})
		.then((newsCards) => {
			if (newsCards.length == 0) {
				loadMoreButton.style.display = "none";
			}
			newsContent.insertAdjacentHTML("beforeend", newsCards);
		})
		.catch((err) => console.log(err));
};

newsCategories.forEach((category) => {
	category.addEventListener("click", () => {
		newsCategories.forEach((c) => {
			c.classList.remove("active");
		});
		category.classList.add("active");
		const categoryTitle = category.getAttribute("data-category-header");
		if (newsContent) {
			while (newsContent.lastChild) {
				newsContent.removeChild(newsContent.lastChild);
			}
		}
		filterCategory(categoryTitle);
		fetchNews(categoryTitle);
	});
});

if (loadMoreButton) {
	loadMoreButton.addEventListener("click", () => {
		let numberOfCards = document.querySelectorAll("#news-content .news-card-wrap").length;
		const activeCategory = document.querySelector(".category.active").getAttribute("data-category-header");
		fetchNews(activeCategory, numberOfCards);
	});
}
